// Fonts
@font-face {
  font-family: 'adventBold1';
  src: url('../fonts/advent-bd1-webfont.eot');
  src: url('../fonts/advent-bd1-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/advent-bd1-webfont.woff') format('woff'),
  url('../fonts/advent-bd1-webfont.ttf') format('truetype'),
  url('../fonts/advent-bd1-webfont.svg#adventBold1') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'adventBold2';
  src: url('../fonts/advent-bd2-webfont.eot');
  src: url('../fonts/advent-bd2-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/advent-bd2-webfont.woff') format('woff'),
  url('../fonts/advent-bd2-webfont.ttf') format('truetype'),
  url('../fonts/advent-bd2-webfont.svg#adventBold2') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'adventRegular';
  src: url('../fonts/advent-re-webfont.eot');
  src: url('../fonts/advent-re-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/advent-re-webfont.woff') format('woff'),
  url('../fonts/advent-re-webfont.ttf') format('truetype'),
  url('../fonts/advent-re-webfont.svg#adventRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: 'blanchcaps';
  src: url('../fonts/blanch_caps-webfont.woff2') format('woff2'),
  url('../fonts/blanch_caps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

//mixins
@mixin skew($side: right, $deg: -20deg, $right: -15px, $left: -15px, $width: 30px, $bg: #fff) {
  position: relative;

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;

    @if $side == right {
      right: $right;
    }
    @else if $side == left {
      left: $left;
    }

    display: block;
    width: $width;
    background: $bg;
    -webkit-transform: skew($deg);
    -ms-transform: skew($deg);
    transform: skew($deg);
  }
}

// Basics
@import "base";

// Bootstrap
@import "bower_components/bootstrap/scss/bootstrap";
@import "bower_components/fontawesome/scss/font-awesome.scss";

// colors
$red: #b90e0c;
$blue: #262746;
$grey-txt: #706f6f;
$light-gray:#eeeeed;
$font-primary: blanchcaps, sans-serif, ariel;
$font-secondary: adventRegular, sans-serif, ariel;

//html{height: 100%}
body {
  height: 100%;
  min-height: 100%;
  overflow: auto;
  text-align: center;
  font-family: adventBold1,sans-serif;
  color: $blue;
  background: url("../images/v2/top-right.png") no-repeat top right,
  url("../images/v2/left-center.png") no-repeat -5% 95%;
  background-size:30%, 18%;
  -webkit-tap-highlight-color: rgba($red,0.5);
  padding-bottom: 50px;
  @include media-breakpoint-down(sm){
    background-size:30%, 0;
  }
}
main{min-height: calc(100vh - 50px )}
.hidden{
  display: none !important;
}

::selection {
  background: $grey-txt;
  color: #fff;
}

a {
  color: $blue;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: darken($blue,15%);
    text-decoration: none;
  }
}

.page{
  display: none;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(.5, 0, .1, 1);
  &.show{
    display: block;
  }
}

.tooltip{
  opacity: 1;
}


.container-takafol{
  .simulator-form{
    .infos-survol{
      color: $red;
      text-transform: uppercase;
      font-family: adventBold2, sans-serif;
      float: right;
      text-align: left;
      @include media-breakpoint-down(sm){
        float: none;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 3rem;
        display: block;
      }
    }
    .row{
      @extend .no-gutter;
    }
  }

  .logo{
    @include media-breakpoint-down(md){
      height: auto;
    }
    height: 80px;
    float: left;
    margin-top: .5rem;
  }
  .form-control{
    background-color:#fff;
    border-radius: 0;
    color: $blue;
    font-size: 0.7rem;
    padding: 0.85rem 0.8rem;
    &:focus{
      border-color: $blue;
      border-left-color: transparent;
      @include media-breakpoint-down(sm){
        border-top: 0;
        border-left-color: $blue;
      }
    }
    &.parsley-error {
      border: 1px solid $red;
      padding: 0.84rem 0.8rem;
    }
    @include media-breakpoint-down(sm) {
      box-shadow: 0px 0px 4px 1px rgba(110, 105, 105, 0.20)
    }
  }
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.6rem + 1px);
  }
  .col-form-label{
    padding-top: calc(0.8rem - 1px * 2);
    padding-bottom: calc(0.8rem - 1px * 2);
  }
  label{
    sup{
      top: -1%;
      left: 1%;
      color: #fff;
      font-size: 1.2rem;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    text-transform: uppercase;
    text-align: left;
    background: $red;
    color: #fff;
    font-size: 0.9rem;
    z-index: 1;
    margin-bottom: auto;
    @include media-breakpoint-up(md) {
      @include skew($side: left, $bg: $red, $deg: 45deg, $left: -30px, $width: 50px);
    }
  }
  .charactar{
    margin-top: 30%;
    @include media-breakpoint-down(sm){
      display: none;
    }
  }
  .bloc-infos{
    font-family: $font-primary;
    text-align: center;
    color:$blue;
    text-transform: uppercase;
    margin: 0.5rem 0;
    h3{
      @include media-breakpoint-down(sm){
        margin: 1.5rem auto;
      }
        margin: 3rem auto;
        display: table;
        white-space: nowrap;
        &.simulateur-txt{font-size: 3rem}
        &:before, &:after {
          border-top: 2px solid $red;
          content: '';
          display: table-cell;
          position: relative;
          top: 0.7em;
          width: 25%;
        }
        &:before { right: 5%;}
        &:after { left: 5%; }
    }
    h2{
      line-height: 0.8;
      font-size: 3rem;
      @include media-breakpoint-down(md){
      font-size: 2rem;
      }
    }
    span{color:$red}
   .super{
     //font-family: $font-secondary,sans-serif,Arial;
     font-size: 5rem;
     @include media-breakpoint-down(md){
       font-size: 1.8rem;
     }
   }
   .second{}
  }
  .bloc-infos2{
    margin-bottom: 20px;
    h2{font-size: 2.2rem}
    h3{
      font-size: 1rem;
      width:3%;
      text-align:center;
      border-bottom: 2px solid $red;
      line-height:0.1em;
      margin:10px auto;
      span{
        padding: 0 20px;
        background: #fff;
      }
    }
    p{
      padding: 15px 50px;
      @include media-breakpoint-down(md){
        padding: 15px 0;
    }
    }
  }
  .form-result{
    .infos-approx{
      color: $red;
      margin-left: 30px;
      font-family: adventBold2, sans-serif;
      @include media-breakpoint-down(sm){
        text-align: center;
        float: none;
        margin-left:0;
      }
    }
    .infos-label{
      color: $blue;
      text-transform: uppercase;
      font-family: adventBold2, sans-serif;
      text-align: center;
      padding: 1rem 5rem;
      @include media-breakpoint-down(sm){
        padding: 1rem 0rem;
        font-size: 1rem;
        text-transform: lowercase;
        margin-top:1rem;

      }
    }
    .first-label{
      margin-bottom: 10px;
    }
    label{
      z-index: 1;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
      @include media-breakpoint-down(md){
        text-align: center;
      }
    }
    .form-control-static{
      background-color: #fff;
      border:1px solid rgba(0, 0, 0, 0.15);
      transition:border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      color: $blue;
      border-radius: 0;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      line-height: 1.15;
    }
  }

  .social{
    @include media-breakpoint-down(md){
    }
    position: fixed;
    left:1%;
    bottom: 1%;
    color: #fff;
    a { color: #fff;}
        ul {
        padding-left: 0;
        li {
          display: inline-block;
          list-style: none;
          margin-right: 10px;
        }
      }
  }

  .btn{
    &.btn-reset{
      width:auto;
      min-width: auto;
      background: $grey-txt;
      @include media-breakpoint-down(md) {
        float: none;
        text-align: center;
      }


    }
    &.next-btn{max-width:180px}
    @extend  .btn;
    background-color: $grey-txt;
    border-radius: 0;
    color: #fff;
    font-family: adventBold2, sans-serif, Arial;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    max-width: 380px;
    min-width: 200px;
    text-align: left;
    padding: 0.6rem 1rem;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
      img{
        height:45px;
        top: -20%
      }
    }
    img{
      display: inline-block;
      position: absolute;
      top: -25%;
      right: 1%;
    }
    &:hover{
      background-color: darken($grey-txt,15%);
    }
    &:active, &:focus {
      box-shadow: 0 0 0 2px rgba(171, 171, 171, 0.25);
    }
    @include media-breakpoint-down(sm) {
      &.next-btn {
      text-align: center;
      display: block;
      float: none;
      max-width: 100%;

    }
    }
  }

}



//slider
.label-max, .label-min{
  position: absolute;
  color: $red;
  top: -30%;
}
.label-min{
  left: 7%;
  @include media-breakpoint-down(sm){
    left:0;
  }
}
.label-max{
  right: 40%;
  @include media-breakpoint-down(sm){
    right:0;
  }
}
.slider-horizontal{
  margin: 0 15px;
  width: 50% !important;
  position: absolute;
  left:7%;
  @include media-breakpoint-down(sm){
    //position: initial;
    width: 70% !important;
  }

}
.slider .tooltip.bottom {
  margin-top: 10px;
  margin-left: -38px !important;
  @include media-breakpoint-down(md){
    margin-top: 5px;
  }
}
.tooltip.bottom .tooltip-arrow {
  top: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $red;

}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.slider-handle{
  box-shadow:none;
  background:url("../images/v2/icon-slider.png") no-repeat center center;
  width: 30px;
  height: 30px;
}
.slider-track{
  height: 10px !important;
  margin-top:-1px !important;
  background: #fbf9f9;
  top: 60% !important;
}
.slider-selection{
  background: url("../images/v2/patern-slider.svg") repeat-x;
  height: 10px;
}
.tooltip-inner{
  padding: 5px 10px;
  background-color: $red;
  border-radius: 0;
  max-width: 100%;

}
.tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before{
  border-right-color:$red ;
  border-width: 5px 6px 5px 0;
}
.tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: $red;
}
.tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom{
  margin-top: 0;
}
//loader
.loader{
  background: rgba($blue,0.5);
  .spinner > div{
    background-color: $red;
  }
}


footer{
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#b2b2b2;
  color: white;
  padding: 0.5rem 1rem;
  height:30px;
  text-align: left;
  .social-container{
    background: $red;
    position: absolute;
    border-right: 5px solid white;
    overflow: hidden;
    transform: skewX(45deg);
    top: -10px;
    left: -30px;
    width: 40%;
    height: 40px;
    a{
      position: absolute;
      left: 40px;
      top: 7px;
      color: #fff;
      transform: skewX(-45deg);
      &:hover{
        color: $light-gray;
      }
    }
  }
}

//modal
.modal{
  z-index: 1080;
  .bloc-infos{
    //to do
  }
  h3{
    margin: 1.5rem auto !important;
    @include media-breakpoint-down(sm){
      font-size: 1.2rem;

    }

  }
  .btn{margin : 0 auto}
  .close{
    position: absolute;
    right:-2px;
    top:-2px;
    opacity: 1;
  }

  .modal-content{
    background: #eeeeed url("../images/v2/bg-modal2.png") no-repeat left top;
    background-size: 100%;
    padding:5% 15%;
  }


}
